import {useRouter} from 'next/router';

import {
  regionConfigs,
  IRegionalStoreConfig,
  ValidRegionCode,
  isValidRegion,
  isSupportedLocale,
} from 'src/config';
import {LocalizedParams} from 'src/types';
import {pathContainsLocalizationPrefix} from 'src/utils/router';

type RegionData = LocalizedParams & {
  regionConfig: IRegionalStoreConfig<ValidRegionCode>;
};

export const useRegionData = (): RegionData => {
  const {
    query: {region, locale},
    asPath,
  } = useRouter();

  if (!region || !locale) {
    // handle 404 page
    const queryFromPath = pathContainsLocalizationPrefix(asPath);
    if (!queryFromPath) {
      return {region: 'us', locale: 'en', regionConfig: regionConfigs['us']};
    }
    return {
      ...queryFromPath,
      regionConfig: regionConfigs[queryFromPath.region],
    };
  }

  if (!isValidRegion(region) || !isSupportedLocale(locale)) {
    throw new Error(
      `Invalid region ( ${region}) or locale ( ${locale}) from url query.`
    );
  }

  return {region, locale, regionConfig: regionConfigs[region]};
};
