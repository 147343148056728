import React, {useEffect} from 'react';

import type {AppProps} from 'next/app';
import {useRouter} from 'next/router';

import {GoogleTagManager} from 'src/components/GoogleTagManager';
import {isLocal} from 'src/config/env';
import {DEFAULT_LOCALE} from 'src/config/locales';
import {CartProvider} from 'src/features/cart/contexts';
import {getReferrerIdFromUrl, setReferrerId} from 'src/features/cart/helpers';
import {useInitIntercom} from 'src/hooks/useInitIntercom';
import Bugsnag from 'src/utils/analytics/bugsnag';
import {Log} from 'src/utils/logger';

import 'src/styles/normalize.css';
import 'src/styles/globals.css';

import CustomErrorPage from './_error';

declare global {
  interface Window {
    /** Google analytics object */
    ga: any;
    /** Google analytics dataLayer */
    dataLayer?: any[];
    /** Apple Sign In manager object */
    AppleID?: any;
    /** Social Platform sign in manager object */
    hello?: any;
    Intercom?: any;
  }
}

const createBugsnagBoundary = () => {
  const BugsnagComponent = Bugsnag.getPlugin('react')?.createErrorBoundary(React as any);

  if (BugsnagComponent) {
    return {
      ErrorBoundary: BugsnagComponent,
      errorBoundaryProps: {FallbackComponent: CustomErrorPage},
    };
  }

  if (!isLocal) {
    Log.error(
      `Could not create Bugsnag Error Boundary. Check Bugsnag setup to make sure everything is correct.`
    );
  }

  return {ErrorBoundary: React.Fragment, errorBoundaryProps: {}};
};

const {ErrorBoundary, errorBoundaryProps} = createBugsnagBoundary();

function MyApp({Component, pageProps}: AppProps): React.ReactNode {
  const {query} = useRouter();
  const lang = query.locale || DEFAULT_LOCALE;

  useEffect(() => {
    if (typeof lang === 'string') {
      document.documentElement.lang = lang;
    }
  }, [lang]);

  useEffect(() => {
    const referrerIdFromUrl = getReferrerIdFromUrl();
    if (referrerIdFromUrl) {
      setReferrerId(referrerIdFromUrl);
    }
  }, []);

  useInitIntercom();

  return (
    <ErrorBoundary {...errorBoundaryProps}>
      <GoogleTagManager>
        <CartProvider>
          <Component {...pageProps} />
        </CartProvider>
      </GoogleTagManager>
    </ErrorBoundary>
  );
}

export default MyApp;
