// GENERATED FILE - DO NOT MODIFY DIRECTLY.
// Add countries to regions by modifying the CSV files in the "data/allowed-countries-by-region" directory.
// Then, to regenerate this file, run the create-region-dicts script.

export const eu = {
  AT: 'Austria',
  BG: 'Bulgaria',
  BE: 'Belgium',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
};
