import {createContext, useContext, FC} from 'react';

import {buildEmptyRegionalCart} from 'src/features/cart/helpers';
import {RegionalCart} from 'src/features/cart/types';

import {useCartState, CartState} from './useCartState';

const CartContext = createContext<CartState | undefined>(undefined);
CartContext.displayName = 'CartContext';

export const CartProvider: FC<{initialCartState?: RegionalCart}> = ({
  children,
  initialCartState = buildEmptyRegionalCart(),
}) => {
  return (
    <CartContext.Provider value={useCartState({initialCartState})}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartState => {
  const ctx = useContext(CartContext);

  if (!ctx) {
    throw new Error('useCart must be used inside CartContext!');
  }

  return ctx;
};
