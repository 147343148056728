import {css} from 'styled-components';

import {dimensions} from '@stryd/react-ui';

export const largeH2 = css`
  font-size: 4.5rem;
  margin-bottom: 1.5rem;
  word-wrap: normal;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    font-size: 3rem;
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobileSmall}) {
    font-size: 2.75rem;
  }
`;

export const largeH3 = css`
  font-size: 3rem;
  margin-bottom: 1.5rem;
  word-wrap: normal;

  @media screen and (max-width: ${dimensions.breakpoints.tabletLandscape}) {
    font-size: 2.75rem;
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobile}) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: ${dimensions.breakpoints.mobileSmall}) {
    font-size: 2.25rem;
  }
`;
