import {REFERRER_ID_KEY} from 'src/features/cart/constants';

export const getReferrerIdFromUrl = (): string | null => {
  return new URLSearchParams(window.location.search).get(REFERRER_ID_KEY);
};

export const getReferrerId = (): string | null => {
  return sessionStorage.getItem(REFERRER_ID_KEY);
};

export const setReferrerId = (id: string | null): void => {
  if (id) {
    sessionStorage.setItem(REFERRER_ID_KEY, id);
  } else {
    sessionStorage.removeItem(REFERRER_ID_KEY);
  }
};
