import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import {isLocal} from 'src/config/env';

const getBugsnagKey = () => {
  if (!process.env.NEXT_PUBLIC_BUGSNAG_BROWSER_API_KEY) {
    throw new Error(
      'You must define NEXT_PUBLIC_BUGSNAG_BROWSER_API_KEY in your environment variables'
    );
  }

  return (
    process.env.BUGSNAG_SERVER_API_KEY ||
    process.env.NEXT_PUBLIC_BUGSNAG_BROWSER_API_KEY
  );
};

/* eslint-disable */
const MockBugsnag = {
  start: (..._: any[]) => {},
  notify: (..._: any[]) => {},
  getPlugin: (..._: any[]) => ({
    createErrorBoundary: () => null,
  }),
} as typeof Bugsnag;
/* eslint-enable */

const BugsnagInstance = isLocal ? MockBugsnag : Bugsnag;

// any cast here because the _client paramater doesn't exist on Bugsnag's types.
// this check prevents the client from being loaded for each page during the next.js build
if (!(BugsnagInstance as any)._client) {
  BugsnagInstance.start({
    apiKey: getBugsnagKey(),
    plugins: [new BugsnagPluginReact() as any],
  });
}

export default BugsnagInstance;
