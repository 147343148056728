import {Asset} from 'contentful';
import {Entry} from 'contentful';

import {ValidRegionCode, validRegionKeys} from 'src/config';
import {colors} from 'src/styles';

import {
  CONTENT_TYPE,
  ITopicSection,
  IInDepthTopicSection,
  IProduct,
  ILinkedContentSection,
} from './generated';

export const INDEX_PAGE_SLUG = 'index';

/** Slugs that will define top level web pages, e.g. 'store' => buy.stryd.com/store, rather than buy.stryd.com/pages/store */
export const indexSlugs = {
  [INDEX_PAGE_SLUG]: INDEX_PAGE_SLUG,
  store: 'store',
  cart: 'cart',
  diversity: 'diversity',
};

export type ContentTypeRecord<T> = Record<CONTENT_TYPE, T>;

export type ContentTypeIdsDict = {
  [K in CONTENT_TYPE]: K;
};

export const ContentTypeIds: ContentTypeIdsDict = {
  topicSection: 'topicSection',
  heroSection: 'heroSection',
  heroPersonalizedSection: 'heroPersonalizedSection',
  heroVideo: 'heroVideo',
  typographySection: 'typographySection',
  tabSection: 'tabSection',
  powerMeterSection: 'powerMeterSection',
  cardScrollSection: 'cardScrollSection',
  cardScrollSections: 'cardScrollSections',
  regionalContentContainer: 'regionalContentContainer',
  webPage: 'webPage',
  pageLinkCta: 'pageLinkCta',
  product: 'product',
  productVariant: 'productVariant',
  stripeProduct: 'stripeProduct',
  productCollection: 'productCollection',
  collectionLink: 'collectionLink',
  featuredHeroProduct: 'featuredHeroProduct',
  featureDescription: 'featureDescription',
  shortFeatureDescriptionGroup: 'shortFeatureDescriptionGroup',
  featuredProductSection: 'featuredProductSection',
  featuredMembershipSection: 'featuredMembershipSection',
  powerCenterMembershipOptions: 'powerCenterMembershipOptions',
  membershipFeaturesTable: 'membershipFeaturesTable',
  experimentContainer: 'experimentContainer',
  inDepthTopicSection: 'inDepthTopicSection',
  textSection: 'textSection',
  featureBreakdown: 'featureBreakdown',
  emailListSignup: 'emailListSignup',
  emailListSignupKlaviyo: 'emailListSignupKlaviyo',
  testimonial: 'testimonial',
  testimonialsContainer: 'testimonialsContainer',
  podcastLinksSection: 'podcastLinksSection',
  productsCollectionSection: 'productsCollectionSection',
  discoverArticle: 'discoverArticle',
  discoverCategory: 'discoverCategory',
  discoverFeed: 'discoverFeed',
  threeColumnSection: 'threeColumnSection',
  contentChunk: 'contentChunk',
  banner: 'banner',
  layout: 'layout',
  post: 'post',
  linkedContent: 'linkedContent',
  linkedContentSection: 'linkedContentSection',
  horizontalScrollSection: 'horizontalScrollSection',
  brandCards: 'brandCards',
  testimonials: 'testimonials',
  highlightTopicSection: 'highlightTopicSection',
};

/**
 * the contentful asset stores the url as '//host.url.com/some-img.jpg'
 * @param image contentful asset
 */
export const getImageSourceFromAsset = (image: Asset): string => {
  return `https:${image.fields.file.url}`;
};

/**
 * Retrieve the alt text from a contentful image asset
 * @param image contentful asset
 */
export const getImageAltFromAsset = (image: Asset): string => {
  return image.fields.description || image.fields.title || '';
};

/**
 * Retrieve the image height from a contentful image asset
 * @param image contentful asset
 */
export const getImageHeightFromAsset = (image: Asset): number => {
  return image.fields.file.details.image?.height ?? 0;
};

/**
 * Retrieve the image width from a contentful image asset
 * @param image contentful asset
 */
export const getImageWidthFromAsset = (image: Asset): number => {
  return image.fields.file.details.image?.width ?? 0;
};

export const isProductContentType = (data: any): data is IProduct => {
  if (!data || typeof data !== 'object') return false;

  if (data.sys?.contentType?.sys?.id === ContentTypeIds.product) {
    return true;
  }

  return false;
};

export type RegionsWithAllOption = ValidRegionCode | 'all';
/**
 * Checks to see if a region is in the provided list
 * Contentful items often have a 'includedIn' type list that lists ValidRegionCodes as well as an 'all' option
 */
export const isAllowedRegion = (
  region: ValidRegionCode,
  allowedList: RegionsWithAllOption[] = validRegionKeys
): boolean => {
  return allowedList.includes('all') || allowedList.includes(region);
};

export const isInDepthTopicSectionEntry = (
  entry: Entry<unknown>
): entry is IInDepthTopicSection => {
  return entry.sys.contentType.sys.id === ContentTypeIds.inDepthTopicSection;
};

export const isTopicSectionEntry = (
  entry: Entry<unknown>
): entry is ITopicSection => {
  return entry.sys.contentType.sys.id === ContentTypeIds.topicSection;
};

export const isGeneralTopicSectionEntry = (
  entry: Entry<unknown>
): entry is ITopicSection | IInDepthTopicSection => {
  return isTopicSectionEntry(entry) || isInDepthTopicSectionEntry(entry);
};

/**
 * Find the position of a certain `TopicSection` or `InDepthTopicSection` within a chain of `TopicSection`s or `InDepthTopicSection`s
 * @param sections all page sections to search
 * @param startAtId a `TopicSection` or `InDepthTopicSection` id to start the search at within `sections`
 */
export const findTopicChainIndex = (
  sections: Entry<unknown>[],
  startAtId: string
): number => {
  const currIndex = sections.findIndex(
    (section) => section.sys.id === startAtId
  );

  if (currIndex <= 0) {
    return currIndex;
  }

  let chainStartIndex = currIndex;
  for (let i = currIndex - 1; i >= 0; i--) {
    const entry = sections[i];
    if (!isGeneralTopicSectionEntry(entry)) {
      break;
    }
    chainStartIndex = i;
  }

  return currIndex - chainStartIndex;
};

export const isLinkedContentSection = (
  entry: Entry<unknown>
): entry is ILinkedContentSection => {
  return entry.sys.contentType.sys.id === ContentTypeIds.linkedContentSection;
};

/**
 *
 * @param sections all page sections to search
 * @param startAtId a `LinkedContentSection` id to start the search at within `sections`
 */
export const findLinkedSectionChainIndex = (
  sections: Entry<unknown>[],
  startAtId: string
): number => {
  const currIndex = sections.findIndex(
    (section) => section.sys.id === startAtId
  );

  if (currIndex <= 0) {
    return currIndex;
  }

  let chainStartIndex = currIndex;
  for (let i = currIndex - 1; i >= 0; i--) {
    const entry = sections[i];
    if (!isLinkedContentSection(entry)) {
      break;
    }
    chainStartIndex = i;
  }

  return currIndex - chainStartIndex;
};

export const getFontColor = (
  background: keyof (typeof colors)['sectionBackground'],
  backgroundImage?: Asset
): 'white' | 'inherit' => {
  if (backgroundImage) {
    return 'white';
  }

  switch (background) {
    case 'dark':
    case 'medium_dark':
      return 'white';

    case 'light':
    case 'medium_light':
      return 'inherit';

    default:
      return 'inherit';
  }
};

export const getBackgroundColor = (
  background: keyof (typeof colors)['sectionBackground'],
  backgroundImage?: Asset
): string | undefined => {
  const c = {
    ...colors.sectionBackground,
    // TODO (Ray): temp fix
    light: undefined,
    medium_light: 'rgba(246, 246, 246, .5)',
  };
  return backgroundImage ? undefined : c[background];
};

export const getButtonColor = (href: string): string | undefined => {
  return href.includes('/store') ? '#FBBF51' : '#17b4ea';
};
