import {buildRegionalRecord} from 'src/config';
import {Cart, RegionalCart} from 'src/features/cart/types';

export const buildEmptyCart = (): Cart => ({
  lineItems: [],
  checkoutSessionId: null,
  cartTestType: null,
  gift: undefined,
});

export const buildEmptyRegionalCart = (): RegionalCart => {
  return buildRegionalRecord(buildEmptyCart);
};
