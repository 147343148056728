import {typedObjectKeys} from '@stryd/util-lib';

import {RegionalCart, StorageRegionalCart} from 'src/features/cart/types';

export const saveRegionalCartToStorage = (
  regionalCart: RegionalCart
): StorageRegionalCart => {
  const result: StorageRegionalCart = {} as StorageRegionalCart;
  typedObjectKeys(regionalCart).forEach((region) => {
    const cart = regionalCart[region];
    result[region] = {
      ...cart,
      lineItems: cart.lineItems.map((lineItem) => ({
        quantity: lineItem.quantity,
        productIds: {
          contentfulProductId: lineItem.product.sys.id,
          contentfulVariantId: lineItem.selectedVariant.sys.id,
        },
      })),
    };
  });
  return result;
};
