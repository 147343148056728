import stripeProductsJson from 'src/cached-data/stripe-products.json';
import {listToDict} from 'src/utils/arrays/listToDict';

import {StripeProductLookup, StrydStripeProduct} from '../types';

export const getStripeProducts = (): StrydStripeProduct[] =>
  stripeProductsJson.stripeProducts as unknown as StrydStripeProduct[];

export const stripeProductsDict: StripeProductLookup = listToDict(
  getStripeProducts()
);
