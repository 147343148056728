import {typedObjectKeys} from '@stryd/util-lib';

import {withBasePath} from 'src/utils/router/withBasePath';

import {Alpha2Code} from './country-region';
import {regionDicts} from './country-region/data/generated/region-dicts';
import {SupportedLocale} from './locales';

export type ValidRegionCode = keyof Omit<typeof regionDicts, 'blocked'>;
export type RegionalStoreRecord<T> = Record<ValidRegionCode, T>;
export type RegionalCountryCodeLists = Record<ValidRegionCode, Alpha2Code[]>;

export type SupportedCurrency = 'AUD' | 'CAD' | 'EUR' | 'USD' | 'JPY' | 'GBP';

export interface IRegionalStoreConfig<T extends ValidRegionCode> {
  regionCode: T;
  locales: SupportedLocale[];
  defaultLocale: SupportedLocale;
  currency: SupportedCurrency;
  flagIconSrc: string;
  primarySalesPackage: 'bundle' | 'free-trial';
}

export type RegionConfigsDict = {
  [K in ValidRegionCode]: IRegionalStoreConfig<K>;
};

const getEmptyCountryListsByRegion = (): RegionalCountryCodeLists => {
  const emptyCountryCodesByRegion: RegionalCountryCodeLists = {
    au: [],
    ca: [],
    eu: [],
    gl: [],
    jp: [],
    uk: [],
    us: [],
  };

  return emptyCountryCodesByRegion;
};

export const supportedRegions = typedObjectKeys(regionDicts);

export const countryCodesByRegion: RegionalCountryCodeLists =
  supportedRegions.reduce((countryLists, currRegion) => {
    const currRegionDict = regionDicts[currRegion];
    return {...countryLists, [currRegion]: typedObjectKeys(currRegionDict)};
  }, getEmptyCountryListsByRegion());

export const regionNames: RegionalStoreRecord<string> = {
  us: 'United States',
  eu: 'Europe',
  uk: 'United Kingdom',
  ca: 'Canada',
  jp: 'Japan',
  gl: 'Global',
  au: 'Australia',
};

export const DEFAULT_REGION: ValidRegionCode = 'us';

export const regionConfigs: RegionConfigsDict = {
  us: {
    regionCode: 'us',
    locales: ['en', 'es'],
    defaultLocale: 'en',
    currency: 'USD',
    flagIconSrc: withBasePath('/icons/flags/us.svg'),
    primarySalesPackage: 'bundle',
  },
  eu: {
    regionCode: 'eu',
    locales: ['en', 'es', 'de', 'fr'],
    defaultLocale: 'en',
    currency: 'EUR',
    flagIconSrc: withBasePath('/icons/flags/eu.svg'),
    primarySalesPackage: 'free-trial',
  },
  uk: {
    regionCode: 'uk',
    locales: ['en'],
    defaultLocale: 'en',
    currency: 'GBP',
    flagIconSrc: withBasePath('/icons/flags/gb.svg'),
    primarySalesPackage: 'bundle',
  },
  ca: {
    regionCode: 'ca',
    locales: ['en', 'fr'],
    defaultLocale: 'en',
    currency: 'CAD',
    flagIconSrc: withBasePath('/icons/flags/ca.svg'),
    primarySalesPackage: 'bundle',
  },
  au: {
    regionCode: 'au',
    locales: ['en'],
    defaultLocale: 'en',
    currency: 'AUD',
    flagIconSrc: withBasePath('/icons/flags/au.svg'),
    primarySalesPackage: 'bundle',
  },
  jp: {
    regionCode: 'jp',
    locales: ['en'],
    defaultLocale: 'en',
    currency: 'JPY',
    flagIconSrc: withBasePath('/icons/flags/jp.svg'),
    primarySalesPackage: 'bundle',
  },
  gl: {
    regionCode: 'gl',
    locales: ['en', 'es', 'fr', 'de'],
    defaultLocale: 'en',
    currency: 'USD',
    flagIconSrc: withBasePath('/icons/flags/un.svg'),
    primarySalesPackage: 'free-trial',
  },
};

export const validRegionKeys = typedObjectKeys(regionConfigs);
export const regionalConfigsArr = validRegionKeys.map(
  (key) => regionConfigs[key]
);

export const isValidRegion = (tested: unknown): tested is ValidRegionCode => {
  return (
    typeof tested === 'string' &&
    Object.prototype.hasOwnProperty.call(regionConfigs, tested)
  );
};

export type RegionMapFn<T> = (region: ValidRegionCode) => T;

export const buildRegionalRecord = <T>(
  builder: RegionMapFn<T>
): RegionalStoreRecord<T> => {
  return validRegionKeys.reduce((dict, currRegion) => {
    return {...dict, [currRegion]: builder(currRegion)};
  }, {} as RegionalStoreRecord<T>);
};

type CombinedRegionsAndLocals = {
  region: ValidRegionCode;
  locale: 'en' | 'de' | 'es' | 'fr';
}[];

export const getCombinedRegionsAndLocales = (): CombinedRegionsAndLocals => {
  const result: CombinedRegionsAndLocals = [];

  regionalConfigsArr.forEach((config) => {
    const {locales, regionCode: region} = config;

    locales.forEach((locale) => {
      result.push({region, locale});
    });
  });

  return result;
};
