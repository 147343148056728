import jwt_decode from 'jwt-decode';
import {ITokenUserInfo} from '@stryd/models';

/**
 * Decodes a Stryd JWT and returns the user info
 * @param token
 */
export const decodeJWT = (token: string): ITokenUserInfo => {
  return jwt_decode(token) as ITokenUserInfo;
};
