/**
 * true if it's running on local environment.
 * false if it's running on cloud environment.
 */
export const isLocal = process.env.NODE_ENV !== 'production';

/**
 * true if it's running on production mode.
 * false if it's running on development mode.
 */
export const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';
