import stripePricesJson from 'src/cached-data/stripe-prices.json';
import {isValidRegion} from 'src/config';
import {
  StrydStripePrice,
  ProductPricingTable,
} from 'src/features/pricing/types';
import {stripeProductsDict} from 'src/features/product/data';
import {insertPriceToTable} from 'src/utils/stripe/insertPriceToTable';

/**
 * Get stripe prices which are currently in sale
 */
const stripePrices: StrydStripePrice[] = (
  stripePricesJson.stripePrices as StrydStripePrice[]
).filter((price) => {
  const {product: productRef} = price;
  const stripeProductId =
    typeof productRef === 'string' ? productRef : productRef.id;

  return (
    stripeProductId in stripeProductsDict &&
    isValidRegion(price.metadata.region)
  );
});

export const stripePricesDict: ProductPricingTable = stripePrices.reduce(
  insertPriceToTable,
  {}
);
