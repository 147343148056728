import {SupportedLocale, ValidRegionCode} from 'src/config';
import {filterAvailableProducts} from 'src/features/product/helpers';
import {IProduct, IStripeProduct} from 'src/utils/contentful';

import {StrydStripeProduct} from '../types';

import {contentfulProducts, contentfulProductsDict} from './contentfulProducts';
import {contentfulStripeProductsDict} from './contentfulStripeProduct';
import {stripeProductsDict} from './stripeProducts';

export * from './stripeProducts';
export * from './contentfulProducts';
export * from './contentfulStripeProduct';

export const getStripeProductById = ({
  id,
}: {
  id: string;
}): StrydStripeProduct | undefined => stripeProductsDict[id];

export const getContentfulProducts = (
  {
    locale,
  }: {
    locale: SupportedLocale;
  } = {locale: 'en'}
): IProduct[] => contentfulProducts[locale];

export const getContentfulProductBySlug = ({
  slug,
  locale,
}: {
  slug: string;
  locale: SupportedLocale;
}): IProduct | undefined => {
  return contentfulProductsDict[locale].bySlug[slug];
};

export const getContentfulProductById = ({
  id,
  locale,
}: {
  id: string;
  locale: SupportedLocale;
}): IProduct | undefined => {
  return contentfulProductsDict[locale].byId[id];
};

export const getContentfulStripeProductByStripeProductId = ({
  id,
  locale,
}: {
  id: string;
  locale: SupportedLocale;
}): IStripeProduct | undefined => {
  return contentfulStripeProductsDict[locale].byId[id];
};

export const getStrydContentfulProduct = ({
  locale,
}: {
  locale: SupportedLocale;
}): IProduct => {
  const product = getContentfulProductBySlug({slug: 'stryd', locale});

  if (!product) {
    throw new Error('Could not find Stryd contentful product');
  }

  return product;
};

export const getStrydPlusContentfulProduct = ({
  locale,
}: {
  locale: SupportedLocale;
}): IProduct => {
  const product = getContentfulProductBySlug({
    slug: 'stryd-plus-membership',
    locale,
  });

  if (!product) {
    throw new Error('Could not find Stryd plus membership contentful product');
  }

  return product;
};

export const getStrydDuoContentfulProduct = ({
  locale,
}: {
  locale: SupportedLocale;
}): IProduct => {
  const product = getContentfulProductBySlug({
    slug: 'stryd-duo',
    locale,
  });

  if (!product) {
    throw new Error('Could not find Stryd Duo contentful product');
  }

  return product;
};

export const getAvailableContentfulProducts = ({
  region,
  locale,
}: {
  region: ValidRegionCode;
  locale: SupportedLocale;
}): IProduct[] => {
  const products = getContentfulProducts({locale});
  return filterAvailableProducts(products, region);
};
