import codeNamePairs from './data/country-codes.json';
import countryRegionMap from './data/generated/country-map.json';

type CodeNamePair = typeof codeNamePairs[number];

/**
 * ISO 3166 Country Code
 * Read more here: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 */
export type Alpha2Code = string;

/**
 * ISO 3166 Country Name
 * Read more here: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 */
export type CountryName = string;

type NameMap = Record<CountryName, Alpha2Code | undefined>;
type CodeMap = Record<Alpha2Code, CountryName | undefined>;

const nameMap: NameMap = {};
const codeMap: CodeMap = {};

const mapCodeAndName = (country: CodeNamePair): void => {
  nameMap[country.name.toLowerCase()] = country.code;
  codeMap[country.code.toLowerCase()] = country.name;
};

codeNamePairs.forEach(mapCodeAndName);

/**
 * Returns the country name based on Alpha-2 code
 * See https://www.iban.com/country-codes for valid values
 */
export const getCountryName = (code: Alpha2Code): CountryName | undefined => {
  return codeMap[code.toLowerCase()];
};

/**
 * Returns the country Alpha-2 code based on name
 * See https://www.iban.com/country-codes for valid values
 */
export const getCountryCode = (name: CountryName): Alpha2Code | undefined => {
  return nameMap[name.toLowerCase()];
};

const isValidCountryCode = (
  code?: string
): code is keyof typeof countryRegionMap => {
  return typeof code !== 'undefined' && code in countryRegionMap;
};

export const getCountryRegion = (code: Alpha2Code): string | undefined => {
  if (!code) return undefined;

  const uppercaseCode = code.toUpperCase();

  if (!isValidCountryCode(uppercaseCode)) {
    return undefined;
  }

  return countryRegionMap[uppercaseCode];
};
