import pc from 'picocolors';

import {typedObjectKeys} from '@stryd/util-lib';

type LogType = 'wait' | 'error' | 'warn' | 'ready' | 'info' | 'event';

type LogTypeRecord<T> = Record<LogType, T>;

interface LogTypeConfig {
  prefix: string;
  logMethod: typeof console.log;
}

export const configs: LogTypeRecord<LogTypeConfig> = {
  wait: {prefix: pc.cyan('wait') + '  -', logMethod: console.log},
  error: {prefix: pc.red('error') + ' -', logMethod: console.error},
  warn: {prefix: pc.yellow('warn') + '  -', logMethod: console.warn},
  ready: {prefix: pc.green('ready') + ' -', logMethod: console.log},
  info: {prefix: pc.cyan('info') + '  -', logMethod: console.info},
  event: {prefix: pc.magenta('event') + ' -', logMethod: console.log},
};

export const Log = typedObjectKeys(configs).reduce((logger, configId) => {
  const {prefix, logMethod} = configs[configId];
  return {
    ...logger,
    [configId]: (...messages: any[]) => {
      logMethod(prefix, ...messages);
    },
  };
}, {} as LogTypeRecord<typeof console.log>);
