import {useEffect} from 'react';

import {useRouter} from 'next/router';

import {decodeJWT} from '@stryd/util-lib';

const getIntercomUserHash = (id: string) =>
  new Promise((resolve) => {
    if (localStorage.intercomUserHash) {
      return resolve(localStorage.intercomUserHash);
    }

    return fetch('/api/intercom/hash', {
      method: 'POST',
      body: JSON.stringify({user_id: id}),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.intercomUserHash = data.hash;
        return data.hash;
      });
  });

const blacklist = ['/berlin-marathon'];

export const useInitIntercom = (): void => {
  const {events, pathname} = useRouter();

  useEffect(() => {
    if (blacklist.includes(pathname)) return;

    const jwt = localStorage.token;

    if (!jwt) {
      window.Intercom &&
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'pkfitcw5',
        });

      return;
    }

    const {ID, Email, FirstName, LastName} = decodeJWT(jwt);

    getIntercomUserHash(ID).then((hash) => {
      window.Intercom &&
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'pkfitcw5',
          user_id: ID,
          user_hash: hash,
          name: `${FirstName} ${LastName}`,
          email: Email,
        });

      events.on('routeChangeComplete', () => {
        window.Intercom && window.Intercom('update');
      });
    });
  }, [events, pathname]);
};
