import React from 'react';

import {BugsnagErrorBoundary} from '@bugsnag/plugin-react';
import {NextPageContext} from 'next';
import ErrorPage from 'next/error';

import Bugsnag from 'src/utils/analytics/bugsnag';

type FallbackComponentProps = React.ComponentProps<
  NonNullable<React.ComponentProps<BugsnagErrorBoundary>['FallbackComponent']>
>;

type CustomErrorPageProps = FallbackComponentProps & {
  statusCode?: number | null;
};

export default class CustomErrorPage extends React.Component<CustomErrorPageProps> {
  static async getInitialProps(ctx: NextPageContext) {
    if (ctx.err) Bugsnag.notify(ctx.err);
    return ErrorPage.getInitialProps(ctx);
  }

  render() {
    return <ErrorPage statusCode={this.props.statusCode || 500} />;
  }
}
