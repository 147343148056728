export const dimensions = {
  bannerHeight: '135px',
  sectionVerticalPadding: '85px',
  sectionVerticalPaddingMobile: '65px',
  sectionSidePadding: '125px',
  sectionSidePaddingMobile: '55px',
  defaultPageVerticalPadding: '68px',
  defaultPageVerticalPaddingMobile: '68px',
  defaultPageSidePadding: '50px',
  defaultPageSidePaddingMobile: '5%',
} as const;
