import {typedObjectKeys} from '@stryd/util-lib';

import {SupportedLocale} from 'src/config';
import {IStripeProduct} from 'src/utils/contentful';

import contentfulProductsDeJson from '../cache/contentful-stripe-products.de.json';
import contentfulProductsEnJson from '../cache/contentful-stripe-products.en.json';
import contentfulProductsEsJson from '../cache/contentful-stripe-products.es.json';
import contentfulProductsFrJson from '../cache/contentful-stripe-products.fr.json';

export const localizedContentfulStripeProducts: Record<
  SupportedLocale,
  IStripeProduct[]
> = {
  de: contentfulProductsDeJson.contentfulStripeProducts as unknown as IStripeProduct[],
  en: contentfulProductsEnJson.contentfulStripeProducts as unknown as IStripeProduct[],
  es: contentfulProductsEsJson.contentfulStripeProducts as unknown as IStripeProduct[],
  fr: contentfulProductsFrJson.contentfulStripeProducts as unknown as IStripeProduct[],
};

type ContentfulStripeProductsDict = Record<
  SupportedLocale,
  {
    byId: Record<string, IStripeProduct>;
  }
>;

export const contentfulStripeProductsDict: ContentfulStripeProductsDict =
  typedObjectKeys(localizedContentfulStripeProducts).reduce((dict, locale) => {
    return {
      ...dict,
      [locale]: {
        byId: localizedContentfulStripeProducts[locale].reduce(
          (dict, product) => ({
            ...dict,
            [product.fields.stripeProductId]: product,
          }),
          {}
        ),
      },
    };
  }, {} as ContentfulStripeProductsDict);
