import {isValidRegion} from 'src/config';
import {ProductPricingTable, RegionalStripePrice} from 'src/types';

import {Log} from '../logger';

export const insertPriceToTable = (
  pricesTable: ProductPricingTable,
  currPrice: RegionalStripePrice
) => {
  const {product: productRef} = currPrice;
  const productId = typeof productRef === 'string' ? productRef : productRef.id;

  const {region: metaRegion} = currPrice.metadata;

  if (!isValidRegion(metaRegion)) {
    const errMsg = metaRegion
      ? `Found price (${currPrice.id}) with invalid region: ${metaRegion}`
      : `Found price with no region information: ${currPrice.id}`;

    Log.error(errMsg);

    // throwing here in order to prevent builds from succeeding if there is invalid pricing
    // pricing will be set on Stripe, and it is possible that the regional information for a price is set incorrectly
    throw new Error(errMsg);
  }

  const updatedTable: ProductPricingTable = {
    ...pricesTable,
    [productId]: {
      ...pricesTable[productId],
      [metaRegion]: currPrice,
    },
  };

  return updatedTable;
};
