import {typedObjectKeys} from '@stryd/util-lib';

import {ValidRegionCode, SupportedLocale} from 'src/config';
import {
  RegionalCart,
  Cart,
  StorageRegionalCart,
  StorageCart,
  LineItem,
} from 'src/features/cart/types';
import {getContentfulProductById} from 'src/features/product/data';
import {getVariantFromContentfulProductById} from 'src/features/product/helpers';

export type GetRegionalCartFromStorage = (
  cartFromStorage: StorageRegionalCart,
  locale: SupportedLocale
) => RegionalCart;

export const getRegionalCartFromStorage: GetRegionalCartFromStorage = (
  cartFromStorage,
  locale
) => {
  const result: RegionalCart = {} as RegionalCart;
  typedObjectKeys(cartFromStorage).forEach((region) => {
    result[region] = buildCartFromStorage(
      cartFromStorage[region],
      region,
      locale
    );
  });

  return result;
};

const buildCartFromStorage = (
  cart: StorageCart,
  region: ValidRegionCode,
  locale: SupportedLocale
): Cart => {
  const lineItems: LineItem[] = [];

  for (const lineItem of cart.lineItems) {
    const {
      productIds: {contentfulProductId, contentfulVariantId},
      quantity,
    } = lineItem;
    const product = getContentfulProductById({
      id: contentfulProductId,
      locale,
    });
    if (!product) {
      continue;
    }
    const selectedVariant = getVariantFromContentfulProductById({
      product,
      id: contentfulVariantId,
    });
    if (!selectedVariant) {
      continue;
    }
    lineItems.push({
      quantity,
      product,
      selectedVariant,
    });
  }

  return {
    ...cart,
    lineItems,
  };
};
