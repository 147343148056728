import {StrydStripeProduct} from 'src/features/product/types';

export const getMinRecurringIntervals = (
  product?: StrydStripeProduct
): number => {
  if (!product || !product.metadata.min_recurring_intervals) {
    return 0;
  }

  return parseInt(product.metadata.min_recurring_intervals, 10);
};
