import {IndexableById, LookupTable} from 'src/types';

const idDictReducer = <I extends IndexableById, D extends LookupTable<I>>(
  dict: D,
  item: I
): LookupTable<I> => {
  return {...dict, [item.id]: item};
};

export const listToDict = <T extends IndexableById>(
  list: T[]
): LookupTable<T> => {
  return list.reduce(idDictReducer, {});
};
