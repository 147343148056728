// GENERATED FILE - DO NOT MODIFY DIRECTLY.
// Add countries to regions by modifying the CSV files in the "data/allowed-countries-by-region" directory.
// Then, to regenerate this file, run the create-region-dicts script.

export const blocked = {
  SD: 'Sudan',
  SY: 'Syrian Arab Republic',
  CU: 'Cuba',
  SS: 'South Sudan',
  SO: 'Somalia',
  IR: 'Iran (Islamic Republic of)',
  KP: "Korea (Democratic People's Republic of)",
};
