import {buildRegionalRecord, ValidRegionCode} from 'src/config';
import {buildEmptyCart} from 'src/features/cart/helpers';
import {
  Cart,
  RegionalCart,
  CartTestType,
  LineItem,
  CartActionItem,
} from 'src/features/cart/types';

export interface ReplaceCartAction {
  type: 'REPLACE_CART';
  cart: RegionalCart;
}

export interface RegionalCartAction {
  type: string;
  region: ValidRegionCode;
}

export interface AddToCartAction extends RegionalCartAction {
  type: 'ADD_QTY_TO_CART';
  item: CartActionItem;
  quantity: number;
}

export interface RemoveFromCartAction extends RegionalCartAction {
  type: 'REMOVE_QTY_FROM_CART';
  item: CartActionItem;
  quantity: number;
}

export interface RemoveItemFromCartAction extends RegionalCartAction {
  type: 'REMOVE_ITEM_FROM_CART';
  item: CartActionItem;
}

export interface UpdateCartItemQuantityAction extends RegionalCartAction {
  type: 'UPDATE_ITEM_QUANTITY';
  item: CartActionItem;
  quantity: number;
}

export interface ClearRegionalCartAction extends RegionalCartAction {
  type: 'CLEAR_REGIONAL_CART';
}

export interface ClearAllRegionalCartsAction {
  type: 'CLEAR_ALL_REGIONAL_CARTS';
}

export interface SetCheckoutSessionIdAction extends RegionalCartAction {
  type: 'SET_CHECKOUT_SESSION_ID';
  checkoutSessionId: string | null;
}

export interface UpdateCartTestType extends RegionalCartAction {
  type: 'UPDATE_CART_TEST_TYPE';
  testType: CartTestType | null;
}

export interface SetGift extends RegionalCartAction {
  type: 'SET_GIFT';
  gift: boolean;
}

export type CartAction =
  | ReplaceCartAction
  | AddToCartAction
  | RemoveFromCartAction
  | RemoveItemFromCartAction
  | UpdateCartItemQuantityAction
  | ClearRegionalCartAction
  | SetCheckoutSessionIdAction
  | ClearAllRegionalCartsAction
  | UpdateCartTestType
  | SetGift;

export const regionCartReducer = (
  state: RegionalCart,
  action: CartAction
): RegionalCart => {
  switch (action.type) {
    case 'ADD_QTY_TO_CART':
    case 'REMOVE_QTY_FROM_CART':
    case 'UPDATE_ITEM_QUANTITY':
    case 'REMOVE_ITEM_FROM_CART':
    case 'SET_CHECKOUT_SESSION_ID':
    case 'UPDATE_CART_TEST_TYPE':
    case 'SET_GIFT':
    case 'CLEAR_REGIONAL_CART': {
      return {
        ...state,
        [action.region]: cartReducer(state[action.region], action),
      };
    }
    case 'CLEAR_ALL_REGIONAL_CARTS': {
      return buildRegionalRecord(buildEmptyCart);
    }
    case 'REPLACE_CART': {
      return {...action.cart};
    }
    default:
      return state;
  }
};

const cartReducer = (cart: Cart, action: CartAction): Cart => {
  switch (action.type) {
    case 'ADD_QTY_TO_CART': {
      const {item, quantity} = action;

      const existingIndex = findExistingIndex(cart, item);

      const newLineItems: LineItem[] =
        existingIndex > -1
          ? cart.lineItems.map((lineItem, index) => {
              if (existingIndex === index) {
                return {
                  ...lineItem,
                  quantity: lineItem.quantity + quantity,
                };
              }
              return lineItem;
            })
          : [
              ...cart.lineItems,
              {
                ...item,
                quantity,
              },
            ];

      return {
        ...cart,
        lineItems: newLineItems,
      };
    }
    case 'REMOVE_QTY_FROM_CART': {
      const {item, quantity} = action;

      const existingIndex = findExistingIndex(cart, item);

      if (existingIndex < 0) return cart;

      const newQuantity = cart.lineItems[existingIndex].quantity - quantity;
      const newLineItems: LineItem[] =
        newQuantity <= 0
          ? cart.lineItems.filter((lineItem, index) => {
              return index !== existingIndex;
            })
          : cart.lineItems.map((lineItem, index) => {
              if (existingIndex === index) {
                return {
                  ...lineItem,
                  quantity: newQuantity,
                };
              }
              return lineItem;
            });

      return {
        ...cart,
        lineItems: newLineItems,
      };
    }
    case 'UPDATE_ITEM_QUANTITY': {
      const {item, quantity} = action;

      const existingIndex = findExistingIndex(cart, item);

      const newLineItems: LineItem[] =
        existingIndex > -1
          ? cart.lineItems.map((lineItem, index) => {
              if (existingIndex === index) {
                return {
                  ...lineItem,
                  quantity,
                };
              }
              return lineItem;
            })
          : [
              ...cart.lineItems,
              {
                ...item,
                quantity,
              },
            ];

      return {
        ...cart,
        lineItems: newLineItems,
      };
    }
    case 'REMOVE_ITEM_FROM_CART': {
      const {item} = action;
      return {
        ...cart,
        lineItems: cart.lineItems.filter(({product, selectedVariant}) => {
          return !(
            product.sys.id === item.product.sys.id &&
            selectedVariant.sys.id === item.selectedVariant.sys.id
          );
        }),
        gift: undefined,
      };
    }
    case 'CLEAR_REGIONAL_CART': {
      return buildEmptyCart();
    }
    case 'SET_CHECKOUT_SESSION_ID': {
      return {
        ...cart,
        checkoutSessionId: action.checkoutSessionId,
      };
    }
    case 'UPDATE_CART_TEST_TYPE': {
      return {
        ...cart,
        cartTestType: action.testType,
      };
    }
    case 'SET_GIFT': {
      return {
        ...cart,
        gift: action.gift ? true : undefined,
      };
    }
    default:
      return cart;
  }
};

const findExistingIndex = (cart: Cart, item: CartActionItem) =>
  cart.lineItems.findIndex(({product, selectedVariant}) => {
    return (
      product.sys.id === item.product.sys.id &&
      selectedVariant.sys.id === item.selectedVariant.sys.id
    );
  });
