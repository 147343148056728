import {au} from './au';
import {blocked} from './blocked';
import {ca} from './ca';
import {eu} from './eu';
import {gl} from './gl';
import {jp} from './jp';
import {uk} from './uk';
import {us} from './us';

export const regionDicts = {
  au,
  blocked,
  ca,
  eu,
  gl,
  jp,
  uk,
  us,
};
