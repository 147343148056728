export const supportedLocales = ['en', 'de', 'es', 'fr'] as const;

export type SupportedLocale = typeof supportedLocales[number];

export type LocaleRecord<T> = Record<SupportedLocale, T>;

export type LocaleIdDict = {
  [L in SupportedLocale]: L;
};

export const localeIds: LocaleIdDict = {
  en: 'en',
  de: 'de',
  es: 'es',
  fr: 'fr',
};

export const languageNames: LocaleRecord<string> = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
};

export const DEFAULT_LOCALE: SupportedLocale = 'en';

export const isSupportedLocale = (locale: any): locale is SupportedLocale => {
  return (
    typeof locale === 'string' &&
    Object.prototype.hasOwnProperty.call(localeIds, locale)
  );
};

/**
 * Changes a specific locale code into a generalized one.
 * For example, `en-US` becomes `en`.
 */
export const toShortLocale = (locale: string): string => {
  return locale.slice(0, 2);
};

/**
 * Extracts an array of locales from a request's accept-language header
 * @param acceptedLangsHeader e.g. `fr-CH, fr;q=0.9, en;q=0.8, de;q=0.7, *;q=0.5`
 */
export const extractAcceptedLangsArr = (
  acceptedLangsHeader?: string
): string[] => {
  if (!acceptedLangsHeader) return [];

  return acceptedLangsHeader.split(',').map((weightedLang) => {
    const trimmed = weightedLang.trim();
    const weightStartIndex = trimmed.indexOf(';');
    return weightStartIndex >= 0 ? trimmed.slice(0, weightStartIndex) : trimmed;
  });
};

interface ISupportedLocaleConfig {
  defaultLocale: SupportedLocale;
  validLocales: SupportedLocale[];
}

type SupportedLocaleDict = {
  [K in SupportedLocale]?: K;
};

interface LocaleSelectorFns {
  isValidLocale: (locale: string) => locale is SupportedLocale;
  selectLocale: (locale: string) => SupportedLocale;
}

export const createLocaleSelector = (
  config: ISupportedLocaleConfig
): LocaleSelectorFns => {
  const validLocales: SupportedLocaleDict = config.validLocales.reduce(
    (dict, loc) => ({...dict, [loc]: loc}),
    {} as SupportedLocaleDict
  );

  const isValidLocale = (locale: string): locale is SupportedLocale => {
    return Object.prototype.hasOwnProperty.call(validLocales, locale);
  };

  const selectLocale = (locale: string): SupportedLocale => {
    if (isValidLocale(locale)) {
      return locale;
    }

    const shortLocale = toShortLocale(locale);

    if (isValidLocale(shortLocale)) {
      return shortLocale;
    }

    return config.defaultLocale;
  };

  return {isValidLocale, selectLocale};
};

/**
 * Select a user's best matched locale, or the default locale.
 * If an array of locales is provided, the match is based on array order.
 * For arrays, each member is checked both on the entire locale, as well as the shortened version before checking the next index.
 * @param config set a default and list of available locales to select the locale from
 * @param locales should be a single locale, or an array of locales
 */
export const selectBestLocale =
  (config: ISupportedLocaleConfig) =>
  (locales: string | string[]): SupportedLocale => {
    const {selectLocale, isValidLocale} = createLocaleSelector(config);

    if (Array.isArray(locales)) {
      for (const locale of locales) {
        if (isValidLocale(locale)) {
          return locale;
        }

        const shortLocale = toShortLocale(locale);

        if (isValidLocale(shortLocale)) {
          return shortLocale;
        }
      }

      return config.defaultLocale;
    }

    return selectLocale(locales);
  };

type LocaleMapFn<T> = (locale: SupportedLocale) => T;

export const buildLocaleRecord = <T>(
  builder: LocaleMapFn<T>
): Record<SupportedLocale, T> => {
  return supportedLocales.reduce((dict, currLocale) => {
    return {...dict, [currLocale]: builder(currLocale)};
  }, {} as Record<SupportedLocale, T>);
};
