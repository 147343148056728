import {regionConfigs, ValidRegionCode, validRegionKeys} from 'src/config';
import {LocalizedParams} from 'src/types';

const prefixEndsWithSlashOrQuery = (prefix: string, path: string): boolean => {
  return path[prefix.length] === '/' || path[prefix.length] === '?';
};

export const pathContainsLocalizationPrefix = (
  path: string
): LocalizedParams | undefined => {
  let localizedPrefix: LocalizedParams | undefined = undefined;
  validRegionKeys.some((region) => {
    const locales = regionConfigs[region].locales;

    return locales.some((locale) => {
      const prefix = `/${region}/${locale}`;

      if (
        path.length > prefix.length &&
        !prefixEndsWithSlashOrQuery(prefix, path)
      ) {
        return false;
      }

      if (path.indexOf(prefix) === 0) {
        localizedPrefix = {region, locale};
        return true;
      }

      return false;
    });
  });

  return localizedPrefix;
};

export const pathContainsRegionPrefix = (
  path: string
): ValidRegionCode | undefined => {
  return validRegionKeys.find((region) => {
    const prefix = `/${region}`;

    if (
      path.length > prefix.length &&
      !prefixEndsWithSlashOrQuery(prefix, path)
    ) {
      return false;
    }

    return path.indexOf(prefix) === 0;
  });
};
