import {typedObjectKeys} from '@stryd/util-lib';

import {SupportedLocale} from 'src/config';
import {IProduct} from 'src/utils/contentful';

import contentfulProductsDeJson from '../cache/contentful-products.de.json';
import contentfulProductsEnJson from '../cache/contentful-products.en.json';
import contentfulProductsEsJson from '../cache/contentful-products.es.json';
import contentfulProductsFrJson from '../cache/contentful-products.fr.json';

export const contentfulProducts: Record<SupportedLocale, IProduct[]> = {
  de: contentfulProductsDeJson.products as unknown as IProduct[],
  en: contentfulProductsEnJson.products as unknown as IProduct[],
  es: contentfulProductsEsJson.products as unknown as IProduct[],
  fr: contentfulProductsFrJson.products as unknown as IProduct[],
};

type ContentfulProductsDict = Record<
  SupportedLocale,
  {
    byId: Record<string, IProduct>;
    bySlug: Record<string, IProduct>;
  }
>;

export const contentfulProductsDict: ContentfulProductsDict = typedObjectKeys(
  contentfulProducts
).reduce((dict, locale) => {
  return {
    ...dict,
    [locale]: {
      byId: contentfulProducts[locale].reduce(
        (dict, product) => ({
          ...dict,
          [product.sys.id]: product,
        }),
        {}
      ),
      bySlug: contentfulProducts[locale].reduce(
        (dict, product) => ({
          ...dict,
          [product.fields.slug]: product,
        }),
        {}
      ),
    },
  };
}, {} as ContentfulProductsDict);
