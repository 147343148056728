import {isLocal, isProduction} from 'src/config/env';

import {Event, isEcommerceEvent} from './events';

export const GTM_ID = 'GTM-5CX7WBK';
export const GA_CLIENT_ID_COOKIE_NAME = '_ga';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const pushToDataLayer = <T extends Record<string, any>>(data: T): void => {
  window.dataLayer = window.dataLayer || [];

  if (isLocal || !isProduction) {
    window.dataLayer.push({...data, debug_mode: true});
  } else {
    window.dataLayer.push(data);
  }
};

export const track = (event: Event): void => {
  // clear ecommerce object before pushing new data
  // https://developers.google.com/tag-manager/ecommerce-ga4#clearing_the_ecommerce_object
  if (isEcommerceEvent(event)) {
    pushToDataLayer({ecommerce: null});
  }

  pushToDataLayer(event);
};
