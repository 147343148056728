import {useEffect} from 'react';

import {useRouter} from 'next/router';

import {pageViewEvent} from 'src/utils/analytics/events';
import {track} from 'src/utils/analytics/gtag';

// TODO (Ray): extract this as a hook
export const GoogleTagManager: React.FC = ({children}) => {
  const router = useRouter();

  useEffect(() => {
    const trackPageView = (url: string) => {
      track(pageViewEvent({url}));
    };

    router.events.on('routeChangeComplete', trackPageView);

    return () => {
      router.events.off('routeChangeComplete', trackPageView);
    };
  }, [router.events]);

  return <>{children}</>;
};
