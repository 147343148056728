import {useEffect, useMemo, useReducer, useRef, Dispatch} from 'react';

import {CART_STORAGE_KEY} from 'src/features/cart/constants';
import {
  getRegionalCartFromStorage,
  getSubtotal,
  saveRegionalCartToStorage,
} from 'src/features/cart/helpers';
import {Cart, RegionalCart, StorageRegionalCart} from 'src/features/cart/types';
import {getVariantPricing} from 'src/features/pricing/helpers';
import {MoneyBag} from 'src/features/pricing/types';
import {useRegionData} from 'src/hooks/useRegionData';

import {regionCartReducer, CartAction} from './cartReducer';

export type CartState = [
  Cart & {
    /** The subtotal is the total price paid today, including both recurring charges and one-time charges */
    subtotal: MoneyBag;
    totalNumberOfItems: number;
    hasSubscriptionProduct: boolean;
    storageLoaded: boolean;
  },
  Dispatch<CartAction>
];

export const useCartState = ({
  initialCartState,
}: {
  initialCartState: RegionalCart;
}): CartState => {
  const [state, dispatch] = useReducer(regionCartReducer, initialCartState);
  const {
    region,
    locale,
    regionConfig: {currency},
  } = useRegionData();
  const storageLoaded = useRef(false);

  const {lineItems, ...rest} = state[region];

  const lineItemsPricings = useMemo(
    () =>
      lineItems.map((lineItem) => {
        return getVariantPricing({
          variant: lineItem.selectedVariant,
          region,
          currency,
        });
      }),
    [lineItems, currency, region]
  );

  const subtotal: MoneyBag = useMemo(
    () =>
      getSubtotal({
        lineItems,
        lineItemsPricings,
        currency,
      }),
    [currency, lineItems, lineItemsPricings]
  );

  const totalNumberOfItems = lineItems.reduce((acc, curr) => {
    return acc + curr.quantity;
  }, 0);

  const hasSubscriptionProduct = lineItemsPricings.some((price) => {
    return price.recurringPrices.length > 0;
  });

  useEffect(() => {
    if (storageLoaded.current) return;

    const storage = localStorage.getItem(CART_STORAGE_KEY);
    if (!storage) {
      storageLoaded.current = true;
      return;
    }

    try {
      const cartFromStorage: StorageRegionalCart = JSON.parse(storage);
      dispatch({
        type: 'REPLACE_CART',
        cart: getRegionalCartFromStorage(cartFromStorage, locale),
      });
      storageLoaded.current = true;
    } catch (error) {
      console.log(error);
    }
  }, [locale]);

  useEffect(() => {
    if (!storageLoaded.current) return;

    localStorage.setItem(
      CART_STORAGE_KEY,
      JSON.stringify(saveRegionalCartToStorage(state))
    );
  }, [state]);

  return [
    {
      ...rest,
      lineItems,
      subtotal,
      totalNumberOfItems,
      hasSubscriptionProduct,
      storageLoaded: storageLoaded.current,
    },
    dispatch,
  ];
};
