export const colors = {
  textDark: 'black',
  textLight: '#545659',
  border: '#c4c4c4',
  backgroundLight: '#e9e9e9',
  asideBackground: '#2c2f31',
  asideTextColor: 'white',
  asideTextColorSecondary: '#c4c4c4',
  loadingBaseColor: '#ddd',
  loadingShineColor: '#e8e8e8',
  successText: '#35B86D',
  inputPlaceholder: '#3b3e41',
  focusHighlight: '#17b4ea',
  sectionBackground: {
    light: undefined,
    medium_light: 'rgba(246, 246, 246, .5)',
    medium_dark: 'rgb(84, 86, 89)',
    dark: '#0A0A0D',
  },
  topicHeaders: ['#17b4ea', '#FBBF51', '#C18EF2'],
  ctaButtonColors: ['#17b4ea', '#FBBF51', '#C18EF2'],
  backgroundImageText: 'white',
} as const;
