import {SupportedCurrency} from 'src/config';
import {LineItem} from 'src/features/cart/types';
import {VariantPricing} from 'src/features/pricing/helpers';
import {MoneyBag} from 'src/features/pricing/types';

export const getSubtotal = ({
  lineItems,
  lineItemsPricings,
  currency,
}: {
  lineItems: LineItem[];
  lineItemsPricings: VariantPricing[];
  currency: SupportedCurrency;
}): MoneyBag =>
  lineItems.reduce(
    (acc, curr, index) => {
      const {oneTimePricesTotal, recurringPricesTotals} =
        lineItemsPricings[index];
      const initialRecurringCharges = recurringPricesTotals.reduce(
        (total, recurringCharge) => total + recurringCharge.unit_amount,
        0
      );
      const lineItemUnitAmount =
        curr.quantity *
        (initialRecurringCharges + oneTimePricesTotal.unit_amount);

      return {
        ...acc,
        unit_amount: acc.unit_amount + lineItemUnitAmount,
      };
    },
    {unit_amount: 0, currency}
  );
