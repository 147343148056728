import {IProduct, IProductVariant} from 'src/utils/contentful';

export const getVariantFromContentfulProductBySlug = ({
  product,
  slug,
}: {
  product: IProduct;
  slug: string;
}): IProductVariant | undefined =>
  product.fields.variants.find((variant) => variant.fields.slug === slug);

export const getVariantFromContentfulProductById = ({
  product,
  id,
}: {
  product: IProduct;
  id: string;
}): IProductVariant | undefined =>
  product.fields.variants.find((variant) => variant.sys.id === id);
