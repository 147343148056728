import {ValidRegionCode} from 'src/config';
import {StrydStripePrice} from 'src/features/pricing/types';

import {stripePricesDict} from './stripePrices';

export * from './stripePrices';

export const getStripePriceByStripeProductId = ({
  id,
  region,
}: {
  id: string;
  region: ValidRegionCode;
}): StrydStripePrice | undefined => stripePricesDict[id]?.[region];
