import {Stripe} from 'stripe';

import {ValidRegionCode, SupportedCurrency} from 'src/config';
import {getStripePriceByStripeProductId} from 'src/features/pricing/data';
import {
  getMinRecurringIntervals,
  isOneTimeStripePrice,
  isRecurringStripePrice,
} from 'src/features/pricing/helpers';
import {
  StrydStripePrice,
  OneTimeStripePrice,
  RecurringStripePrice,
  MoneyBag,
  RecurringMoneyBag,
} from 'src/features/pricing/types';
import {getStripeProductById} from 'src/features/product/data';
import {IProductVariant} from 'src/utils/contentful';

type RecurringIntervalMoneyBagRecord = Partial<
  Record<Stripe.Price.Recurring.Interval, RecurringMoneyBag>
>;

const isOneTimeStripePriceWithCurrency = (currency: string) => {
  return (price: StrydStripePrice): price is OneTimeStripePrice => {
    return (
      price.currency.toLowerCase() === currency.toLowerCase() &&
      isOneTimeStripePrice(price)
    );
  };
};

const isRecurringStripPriceWithCurrency = (currency: string) => {
  return (price: StrydStripePrice): price is RecurringStripePrice => {
    return (
      price.currency.toLowerCase() === currency.toLowerCase() &&
      isRecurringStripePrice(price)
    );
  };
};

export type VariantPricing = {
  oneTimePrices: OneTimeStripePrice[];
  recurringPrices: RecurringStripePrice[];
  oneTimePricesTotal: MoneyBag;
  recurringPricesTotals: RecurringMoneyBag[];
};

/**
 * Get a breakdown of total pricing for a product variant.
 *
 * If there are multiple Stripe products that are lumped together in this variant,
 * one time prices are all lumped together and recurring prices are lumped together according to charge interval (e.g. monthly, yearly)
 */
export const getVariantPricing = ({
  variant,
  region,
  currency,
}: {
  variant: IProductVariant;
  region: ValidRegionCode;
  currency: SupportedCurrency;
}): VariantPricing => {
  const prices: StrydStripePrice[] = [];

  variant.fields.stripeProducts.forEach((product) => {
    const price = getStripePriceByStripeProductId({
      id: product.fields.stripeProductId,
      region,
    });
    if (!price) {
      throw new Error('price is not found!');
    }
    prices.push(price);
  }, []);

  const oneTimePrices: OneTimeStripePrice[] = prices.filter(
    isOneTimeStripePriceWithCurrency(currency)
  );

  const recurringPrices: RecurringStripePrice[] = prices.filter(
    isRecurringStripPriceWithCurrency(currency)
  );

  const oneTimePricesTotal: MoneyBag = oneTimePrices.reduce(
    (total, curr) => {
      if (!curr.unit_amount) {
        return total;
      }

      const newTotalAmount = total.unit_amount + curr.unit_amount;
      return {...total, unit_amount: newTotalAmount};
    },
    {currency, unit_amount: 0}
  );

  // This total assumes we don't have several subscriptions with the same billing interval bundled together
  // on a single variant. This use case seems highly unlikely and so is not currently supported
  const recurringPricesTotalsByInterval: RecurringIntervalMoneyBagRecord = {};

  recurringPrices.forEach((price) => {
    if (!price.unit_amount) {
      return;
    }

    const {interval, interval_count} = price.recurring;
    const minRecurringIntervals = getMinRecurringIntervals(
      getStripeProductById({id: price.product})
    );
    const existingTotal = recurringPricesTotalsByInterval[interval];

    if (!existingTotal) {
      recurringPricesTotalsByInterval[interval] = {
        currency,
        interval,
        interval_count,
        unit_amount: price.unit_amount,
        min_recurring_intervals: minRecurringIntervals,
      };
    } else {
      existingTotal.unit_amount = existingTotal.unit_amount + price.unit_amount;
    }
  });

  const recurringPricesArr = Object.values(recurringPricesTotalsByInterval);

  return {
    oneTimePrices,
    recurringPrices,
    oneTimePricesTotal,
    recurringPricesTotals: recurringPricesArr,
  };
};
